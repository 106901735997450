var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ManagementLayout',{attrs:{"show":!_vm.isLoadingTable,"control-show":""},scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v("Automation Actions")]},proxy:true},{key:"subtitle",fn:function(){return [(_vm.permissionsCheck('delete'))?_c('span',[_vm._v(" View and manage your team's "),_c('ExternalLink',{attrs:{"href":"https://docs.prefect.io/orchestration/concepts/automations.html#automations"}},[_vm._v("automation actions")])],1):_c('span',[_vm._v("View the "),_c('ExternalLink',{attrs:{"href":"https://docs.prefect.io/orchestration/concepts/automations.html#automations"}},[_vm._v("Automation Actions")]),_vm._v(" of "+_vm._s(_vm.tenant.name))],1)]},proxy:true}])},[_c('v-card',{attrs:{"tile":""}},[_c('v-card-text',{staticClass:"pa-0"},[_c('v-data-table',{staticClass:"elevation-2 rounded-0 truncate-table",class:{ 'fixed-table': _vm.$vuetify.breakpoint.smAndUp },attrs:{"fixed-header":"","headers":_vm.headersByViewport,"header-props":{ 'sort-icon': 'arrow_drop_up' },"items":_vm.actions,"items-per-page":10,"sort-by":_vm.sortBy,"show-expand":"","sort-desc":_vm.sortDesc,"footer-props":{
          showFirstLastPage: true,
          itemsPerPageOptions: [10, 15, 20, -1],
          firstIcon: 'first_page',
          lastIcon: 'last_page',
          prevIcon: 'keyboard_arrow_left',
          nextIcon: 'keyboard_arrow_right'
        },"no-data-text":"This team does not have any actions yet."},on:{"update:sortBy":function($event){_vm.sortBy=$event},"update:sort-by":function($event){_vm.sortBy=$event},"update:sortDesc":function($event){_vm.sortDesc=$event},"update:sort-desc":function($event){_vm.sortDesc=$event}},scopedSlots:_vm._u([{key:"item.id",fn:function(ref){
        var item = ref.item;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
return [_c('div',_vm._g({staticClass:"cursor-pointer text-truncate",on:{"click":function($event){return _vm.copyTextToClipboard(item.id)}}},on),[_vm._v(" "+_vm._s(item.id)+" ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.copiedActionId === item.id ? 'Copied!' : 'Click to copy ID'))])])]}},{key:"item.name",fn:function(ref){
        var item = ref.item;
return [_c('div',{staticClass:"hidewidth"},[_vm._v(" "+_vm._s(item.name)+" ")])]}},{key:"item.type",fn:function(ref){
        var item = ref.item;
return [_vm._v(" "+_vm._s(item.action_type)+" ")]}},{key:"expanded-item",fn:function(ref){
        var headers = ref.headers;
        var item = ref.item;
return [(Object.keys(item.action_config).length)?_c('td',{attrs:{"colspan":headers.length}},[(Object.keys(item.action_config).length)?_c('DictInput',{attrs:{"disableEdit":true,"disabled":"","dict":item.action_config,"rules":[1, 2]}}):_vm._e()],1):_c('td',{attrs:{"colspan":headers.length}},[_vm._v(" No action config ")])]}},{key:"item.actions",fn:function(ref){
        var item = ref.item;
return [(_vm.permissionsCheck('update'))?_c('v-btn',{attrs:{"text":"","fab":"","color":"primary","loading":_vm.isTestingAction === item.id,"x-small":"","title":"Test Action"},on:{"click":function($event){_vm.selectAction(item)
              _vm.testAction(item)}}},[_c('v-icon',[_vm._v("bug_report")])],1):_vm._e(),(_vm.permissionsCheck('delete'))?_c('v-btn',{attrs:{"text":"","fab":"","x-small":"","color":"error","title":"Delete Action"},on:{"click":function($event){_vm.selectAction(item)
              _vm.dialogRemoveAction = true}}},[_c('v-icon',[_vm._v("delete")])],1):_vm._e()]}}])})],1)],1),(_vm.selectedAction)?_c('ConfirmDialog',{attrs:{"type":"error","dialog-props":{ 'max-width': '600' },"disabled":_vm.isRemovingAction,"loading":_vm.isRemovingAction,"title":("Are you sure you want to delete " + (_vm.selectedAction.name ? _vm.selectedAction.name : 'this action') + "?")},on:{"cancel":_vm.closeActionDialog,"confirm":_vm.removeAction},model:{value:(_vm.dialogRemoveAction),callback:function ($$v) {_vm.dialogRemoveAction=$$v},expression:"dialogRemoveAction"}}):_vm._e(),_c('Alert',{attrs:{"type":_vm.alertType,"message":_vm.alertMessage,"offset-x":_vm.$vuetify.breakpoint.mdAndUp ? 256 : 56},model:{value:(_vm.alertShow),callback:function ($$v) {_vm.alertShow=$$v},expression:"alertShow"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }